import React from "react"
import { Link } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Section from "../../components/template-partials/global-components/section"
import ShareButtons from "../../components/template-partials/global-components/share-buttons"
import InteriorMenu from "../../components/template-partials/global-components/interior-menu"
import InteriorPageHeader from "../../components/template-partials/global-components/interior-page-header"
import InteriorPageFooter from "../../components/template-partials/global-components/interior-page-footer"
import FancyImage from "../../components/template-partials/global-components/fancy-image"
import SupportiveCareSubmenu from "../../components/template-partials/supportive-care/supportive-care-submenu"
import Slider from "react-slick-slider"
import SliderNextArrow from "../../components/template-partials/slider-components/slider-next-arrow"
import SliderPrevArrow from "../../components/template-partials/slider-components/slider-prev-arrow"

//Cool! Export your sass Vars from a top line and use them in JS!
import * as SassVars from '../../components/variables.module.scss';
import AlertInfoCard from "../../components/template-partials/global-components/alert-infocard"
import SupportiveCareFooterLinks from "../../components/template-partials/supportive-care/supportive-care-footer-links"

import {UpNextCard} from "../../components/template-partials/global-components/footer-cards"


import headingBg from "../../images/4.0-headerbg.jpg"
import headingRound from "../../images/supportive-care-nav-image.jpg"

import decreasedEating from "../../images/4.4-decreased-eating.jpg";
import feedingTube from "../../images/4.4-feeding-tube.jpg";

import snacks from "../../images/4.4.2_snacks.jpg";
import peanutButter from "../../images/4.4.3_peanutbutter.jpg";
import stockSnacks from "../../images/4.4.4_stocksnacks.jpg";
import snacksAtHospital from "../../images/4.4.5_snacksathospital.jpg";

const DietAndNutritionPage = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: SliderNextArrow,
    prevArrow: SliderPrevArrow,
    responsive: [
      { breakpoint: 1000000, settings: 'unslick' },
      { breakpoint: 1024, settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }},
    ]
  };
  return (
    <Layout>
      <SEO title="Neuroblastoma Supportive Care | Diet & Nutrition" description="Adequate nutrition plays an important role in your child’s treatment journey, but children with neuroblastoma often have difficulty eating. Discover how you can help support your child's nutrition during therapy." />

      <InteriorPageHeader backgroundImage={headingBg} roundImage={headingRound} alt="Decreased Appetite and Nutrition">
        <h1 className="section__heading section__heading--green h1">Decreased Appetite <em>and</em> Nutrition</h1>
      </InteriorPageHeader>

      {/* style={{backgroundColor: SassVars.viridian}} */}

      <Section className="interior-page">
        <div className="row row--inner">
          <div className="columns small-12 large-4">
            <InteriorMenu title={`Supportive Care`}>
              <SupportiveCareSubmenu/>
            </InteriorMenu>
          </div>
          <div className="columns">
            <div className="row">
              <div className="columns">
                <ShareButtons/>
              </div>
            </div>
            <div className="row">
              <div className="columns">
                <p>A decrease in your child's appetite during treatment can keep them from getting the nutrients they need. Ensuring they receive adequate nutrition will be important because it helps support growth and healing throughout the neuroblastoma journey. </p>
                <h2 className="tc-barney jost-med h2">Potential causes of decreased appetite </h2>
                <p>There are many reasons children with cancer may have a decreased appetite or difficulty eating, including:</p>
                <div className="row">
                  <div className="columns mt-1">
                    <ul className="ul--dotted">
                      <li><Link rel="glossary" to="/support-and-community/glossary/#nausea">Nausea</Link> and vomiting</li>
                      <li><Link rel="glossary" to="/support-and-community/glossary/#diarrhea">Diarrhea</Link> or <Link rel="glossary" to="/support-and-community/glossary/#constipation">constipation</Link></li>
                      <li>Mouth sores</li>
                      <li>Tumor location</li>
                    </ul>
                  </div>
                  <div className="columns mt-1">
                    <ul className="ul--dotted">
                      <li>Changes to taste sense from <Link rel="glossary" to="/support-and-community/glossary/#chemotherapy">chemotherapy</Link></li>
                      <li>Eating unfamiliar foods while in the hospital</li>
                      <li>Depression, dehydration, stress, or pain</li>
                      <li>Certain chemotherapy, radiation therapy, and <Link rel="glossary" to="/support-and-community/glossary/#antibody-therapy">antibody therapy</Link> treatments</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Section>

      <Section style={{backgroundColor:SassVars.paleBlue}}>
        <div className="row row--inner align-middle">
          <div className="columns small-12 large-4 text-center large-text-left">
            <FancyImage url={decreasedEating} alt="Signs and symptoms of decreased appetite"/>
          </div>
          <div className="columns">
            <h3 className="tc-barney jost-med h2">Signs and symptoms of decreased appetite</h3>
            <p>When your child’s appetite is decreased, it will be difficult for them to eat enough calories and protein to support their body. This may lead to weight loss. </p>
          </div>
        </div>
      </Section>

      <Section>
        <div className="row row--inner align-middle">
          <div className="columns">
            <h3 className="tc-barney jost-med h2">Treating decreased appetite</h3>
            <p>Because decreased appetite and weight loss are common during neuroblastoma treatment, your child’s healthcare team will follow your child’s growth (height and weight) regularly.  A <Link rel="glossary" to="/support-and-community/glossary/#registered-dietitian">registered dietitian</Link> may work with you and your child to help them maintain or gain weight when necessary.</p>
            <p>Some helpful tips you can follow to support your child’s nutrition during therapy include: </p>
          </div>
        </div>
        <div className="row row--inner lg_mt-2 mt-2 mb-2">
          <div className="columns">
            <Slider className="grid-slider grid-slider--large-4" {...settings}>
              <div className="text-center">
                <img src={snacks} style={{maxWidth:`100px`}} alt=""/>
                <p className="mt-2">Offering snacks or small meals often during the day</p>
              </div>
              <div className="text-center">
                <img src={peanutButter} style={{maxWidth:`140px`}} alt=""/>
                <p className="mt-2">Adding high-calorie foods like peanut butter and cheese to their diet</p>
              </div>
              <div className="text-center">
                <img src={stockSnacks} style={{maxWidth:`100px`}} alt=""/>
                <p className="mt-2">Stocking up on their favorite snacks</p>
              </div>
              <div className="text-center">
                <img src={snacksAtHospital} style={{maxWidth:`50px`}} alt=""/>
                <p className="mt-2">Bringing some of the child’s favorite foods to the hospital (if possible)</p>
              </div>
            </Slider>
          </div>
        </div>
        <div className="row row--inner lg_mt-2">
          <div className="columns">
            <p>Despite everyone’s best efforts, your child may still lose weight during treatment. If the healthcare team determines weight loss is a concern, nutritional supplements or appetite stimulants may be suggested.  </p>
          </div>
        </div>
      </Section>

      <Section style={{backgroundColor:SassVars.honeydew}}>
        <div className="row row--inner">
          <div className="columns">
            <h3 className="tc-barney h2 jost-med">Will my child need a feeding tube? </h3>
            <p>If your child continues to lose weight after exploring different foods and supplements, they may need a <Link rel="glossary" to="/support-and-community/glossary/#feeding-tube">feeding tube</Link>. A feeding tube is a small, soft tube that is inserted through your child’s nose and goes into the stomach—this is called a nasogastric or NG tube. </p>
            <ul className="ul--dotted">
              <li>This type of feeding tube can be placed at the bedside by a nurse. It is a relatively quick procedure and does not require surgery. Once the tube is placed, special nutritional formulas will be given through the tube to provide your child with fluid, calories, protein, vitamins, and minerals</li>
              <li>If your child has a feeding tube, you may be taught how to use it at home. Some oral medications may be given through the feeding tube. Once the tube is no longer needed, it can be easily removed. Feeding tubes can be used throughout treatment to help support your child’s nutrition</li>
            </ul>
          </div>
          <div className="columns large-4 small-12 text-center large-text-left">
            <FancyImage url={feedingTube} alt="Will my child need a feeding tube?"/>
          </div>
        </div>
        <div className="row row--inner lg_mt-4 mt-4">
          <div className="columns">
            <p>If your child is unable to tolerate food going into the stomach, their healthcare team may order a special solution called <Link rel="glossary" to="/support-and-community/glossary/#total-parenteral-nutrition-or-tpn">total parenteral nutrition</Link> (TPN) to be given through your child’s <Link rel="glossary" to="/support-and-community/glossary/#central-line-or-cl">central line</Link>. </p>
            <ul className="ul--dotted">
              <li>TPN is made of 2 liquids: a yellow liquid (contains proteins, carbohydrates, minerals, and vitamins) and a white liquid (contains fats). TPN is usually a short-term method of nutrition until your child is able to eat normally again. TPN may be used during <Link rel="glossary" to="/support-and-community/glossary/#stem-cell-transplant">stem cell transplantation</Link>.</li>
            </ul>
          </div>
        </div>
        <AlertInfoCard className="lg_mt-2" text="Please call your child’s healthcare team if your child experiences unexplained weight loss or gain, or is unable to tolerate tube feedings (persistent vomiting or diarrhea)."/>
      </Section>

      <Section>
        <SupportiveCareFooterLinks/>
      </Section>


      <InteriorPageFooter>

      <UpNextCard topic="Blood Counts" title="Monitoring blood counts" lead="Learn why your child's healthcare team will monitor blood counts during treatment." url="/supportive-care/blood-counts/" />

      </InteriorPageFooter>

    </Layout>
  )
}

export default DietAndNutritionPage
